<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item label-width="0">
				<el-button type="primary" style="background-color:#795A25;border: none;" @click="page_ser">录入</el-button>
				<el-button type="primary" style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="batch_edit">批量修改</el-button>
			</el-form-item>
		</el-form>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="tord" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading" ref='multipleTable'>
				<el-table-column label="运单编号" width="200px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.truck_tord_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">发货地:{{scope.row.case_addr}}</div>
						<div class="tab_line_item">卸货地:{{scope.row.aim_addr}}</div>
					</template>
				</el-table-column>
				<el-table-column label="司机货主手机号" width="180px">
					<template slot-scope="scope">
						<div class="tab_line_item">收款人电话:{{scope.row.truck_owner_info_tel}}</div>
						<div class="tab_line_item">司机电话:{{scope.row.driver_info_tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="总运费" width="180px">
					<template slot-scope="scope">
						<el-input class="el_input" v-model="scope.row.excle_freight_total" placeholder="结算给司机" clearable></el-input>
					</template>
				</el-table-column>
				<el-table-column label="已支付" width="180px">
					<template slot-scope="scope">
						<div class="tab_line_item">￥{{scope.row.has_payed_total}}</div>
					</template>
				</el-table-column>
				<el-table-column label="结算给车队长" width="130px">
					<template slot-scope="scope">
						<el-input class="el_input" v-model="scope.row.give_truck_owner_total" placeholder="结算给车队长" clearable></el-input>
					</template>
				</el-table-column>
				<el-table-column label="车队长选择" width="130px" prop="truck_owner_info">
					<template slot-scope="scope">
						<el-select v-model="scope.row.user_num" placeholder="请选择">
							<el-option
								v-for="item in scope.row.truck_owner_info"
								:key="item.id"
								:label="item.name"
								:value="item.user_num">
							</el-option>
						</el-select>
					</template>
				</el-table-column>
				<el-table-column label="结算给司机" width="130px">
					<template slot-scope="scope">
						<el-input class="el_input" v-model="scope.row.give_driver_total" placeholder="结算给司机" clearable></el-input>
					</template>
				</el-table-column>
			</el-table> 
		 </div>
	    <!-- 回到顶部 -->
        <el-backtop target=".page" :bottom="70" :right="120"></el-backtop>
		<!-- 分页 -->
		<!-- <el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 50, 100, 200]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination> -->


	</div>
</template>

<script>
	export default {
		components:{
		},
		data() {
			return {

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				tord:[],
			}
		},
		created() {

		},
		methods: {
			//批量修改提交
			batch_edit(){
				if(this.tord.length ==0){
					this.$my.other.msg({
						type:'info',
						str:'请先选择数据'
					 })
					 return
				}
				let truck_tord_nums = []
				let truck_cost_list  = []
				//遍历数据
				this.tord.forEach(item=>{
					item.truck_tord_num = item.truck_tord_num.trim()
					truck_tord_nums.push(item.truck_tord_num)
					truck_cost_list.push({
						truck_tord_num:item.truck_tord_num,
						give_truck_owner_total:item.give_truck_owner_total,
						give_driver_total:item.give_driver_total,
						truck_owner_num:item.user_num,
						freight_total:item.excle_freight_total
					})
				})
				//修改数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_cost_edit_by_sub_user',
						truck_tord_nums,
						truck_cost_list,
					},callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'编辑成功'
					    })
						this.tord = []
					}
				})
			},
			//录入功能
            page_ser(){
                this.$my.excle.importExcel((data1, dataRef) => {
					var data = []
					//去除空数据
					data1.forEach((item=>{
						if(item.length >0){
							data.push(item)
						}
					}))
					if(data.length>300){
						this.$my.other.msg({
							type:'info',
							str:'最大录入量为300条,请修改后重新录入'
					    })
						return
					}
					var tels = []
					var tord_list = []
					//获取对应位置下标
					var truck_tord_num_index = data[0].indexOf('运单编号')
					var truck_owner_info_tel_index = data[0].indexOf('收款人电话')
					var driver_info_tel_index = data[0].indexOf('司机电话')
					var excle_freight_total_index = data[0].indexOf('运费')
					var has_payed_total_index = data[0].indexOf('已支付')
					var give_truck_owner_total_index = data[0].indexOf('结算给车队长')
					var give_driver_total_index = data[0].indexOf('结算给司机')
					var case_addr_index1 = data[0].indexOf('发货地省份')
					var case_addr_index2 = data[0].indexOf('发货地城市')
					var case_addr_index3 = data[0].indexOf('发货地县区')
					var case_addr_index4 = data[0].indexOf('发货地详细地址')
					var aim_addr_index1 = data[0].indexOf('到货地省份')
					var aim_addr_index2 = data[0].indexOf('到货地城市')
					var aim_addr_index3 = data[0].indexOf('到货地县区')
					var aim_addr_index4 = data[0].indexOf('到货地详细地址')
					//删除表头
					delete data[0]
					data.forEach((item,index)=> {
						tord_list.push({
							truck_tord_num:item[truck_tord_num_index],
							case_addr:item[case_addr_index1]+ '/' +item[case_addr_index2]+'/' +item[case_addr_index3]+'/' +item[case_addr_index4],
							aim_addr:item[aim_addr_index1]+ '/' +item[aim_addr_index2]+'/' +item[aim_addr_index3]+'/' +item[aim_addr_index4],
							truck_owner_info_tel:item[truck_owner_info_tel_index],
							driver_info_tel:item[driver_info_tel_index],
							excle_freight_total:Number(item[excle_freight_total_index]).toFixed(2),
							has_payed_total:Number(item[has_payed_total_index]).toFixed(2),
							give_truck_owner_total:Number(item[give_truck_owner_total_index]).toFixed(2),
							give_driver_total:Number(item[give_driver_total_index]).toFixed(2),
							tel:item[truck_owner_info_tel_index],
							truck_owner_info:[],
							user_num:'',
						})
						tels.push(String(item[truck_owner_info_tel_index]).trim())
					});
					//console.log(tels,777)
					this.$my.net.req({
						data:{
							mod:'app_user',
							ctr:'user_list_by_app_user',
							tels,
							num:1000
						},callback:(data)=>{
							var data_info = []
							data.list.forEach(item=>{
								if(!data_info[item.tel]){
									data_info[item.tel]=[]
									
								}
								data_info[item.tel].push(item)
							})
							//console.log(data_info)
							tord_list.forEach(item=>{
								// console.log(item)
								item.truck_owner_info=data_info[String(item.tel).trim()]
								var user_info =  data_info[String(item.tel).trim()]
								if(!data_info[String(item.tel).trim()]){
									return
								}
								if(data_info[String(item.tel).trim()].length ==1){
									item.user_num =user_info[0].user_num
								}
							})
							this.list.total =tord_list.length
							this.tord = tord_list
						}
					})

                });
            },
			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
				
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: 100%;
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height: 700px;
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>